.root {
  --card-scroller--padding-x: var(--g--mx-viewport);
  --card-scroller--padding-top: var(--g--my-md);
  --card-scroller--padding-bottom: var(--g--my-md);
  --card-scroller--expand-top: 0px;
  --card-scroller--expand-bottom: 0px;
  --card-scroller--indicator-left: auto;
  --card-scroller--indicator-right: var(--card-scroller--padding-x, var(--g--mx-viewport));
  --card-scroller--indicator-top: auto;
  --card-scroller--indicator-bottom: 100%;

  /* These are computed with JS */
  --card-scroller--thumb-width: 0;
  --card-scroller--thumb-position: 0;

  position: relative;
  display: flex;
  flex-flow: row nowrap;
}

.scroller {
  scroll-behavior: smooth;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  scroll-padding-left: var(--card-scroller--padding-x);
  scroll-snap-type: x mandatory;

  position: relative;

  overflow: hidden;
  overflow-x: scroll;
  display: flex;
  flex-flow: row nowrap;

  margin-top: calc(var(--card-scroller--expand-top) * -1);
  margin-bottom: calc(var(--card-scroller--expand-bottom) * -1);
  padding: calc(var(--card-scroller--padding-top) + var(--card-scroller--expand-top))
    var(--card-scroller--padding-x)
    calc(var(--card-scroller--padding-bottom) + var(--card-scroller--expand-bottom))
    var(--card-scroller--padding-x);

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar in webkit-based browsers */
  }

  /*
  Force grabbing cursor during mouse swipe and don't allow clicks going through.
  */
  &[data-mouse-swipe='true'] {
    cursor: grabbing;

    & * {
      cursor: grabbing !important;
    }

    & button,
    & a {
      pointer-events: none !important;
    }
  }
}

.content {
  display: flex;
  flex-flow: row nowrap;
  gap: var(--g--card-gap);
}

.next,
.prev {
  position: absolute;
  z-index: 2;
  top: var(--card-scroller--padding-top);
  bottom: var(--card-scroller--padding-bottom);

  width: calc(var(--card-scroller--padding-x) - var(--g--card-gap));

  opacity: 0;

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  &.next {
    right: 0;
    border-radius: var(--g--card-border-radius) 0 0 var(--g--card-border-radius);
  }

  &.prev {
    left: 0;
    border-radius: 0 var(--g--card-border-radius) var(--g--card-border-radius) 0;
  }

  html[data-nav-device='mouse'] .root:hover &[data-active='true'] {
    opacity: 1;
  }

  & .button {
    width: 100%;
    height: 100%;
    padding: 0;

    background: var(--g--glass-dark-900);
    backdrop-filter: none;
    border-radius: var(--g--card-border-radius);

    .next & {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .prev & {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    & > svg {
      width: 32px;
      height: 32px;
    }
  }
}

.scrollIndicator {
  pointer-events: none;

  position: absolute;
  inset: var(--card-scroller--indicator-top) var(--card-scroller--indicator-right)
    var(--card-scroller--indicator-bottom) var(--card-scroller--indicator-left);
  transform: scale(0.85);

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 4px;

  opacity: 0;
  background-color: rgb(255 255 255 / 20%);
  border-radius: 2px;

  transition:
    opacity var(--g--anim-duration) var(--g--anim-easing),
    transform var(--g--anim-duration) var(--g--anim-easing);

  html[data-nav-device='mouse'] .root:hover &[data-active='true'],
  html[data-nav-device='keyboard'] .root:focus-within &[data-active='true'] {
    transform: scale(1);
    opacity: 1;
  }

  & .scrollIndicatorThumb {
    position: absolute;
    top: 0;
    left: calc(
      calc(100% - calc(100% * var(--card-scroller--thumb-width))) *
        var(--card-scroller--thumb-position)
    );

    width: calc(100% * var(--card-scroller--thumb-width, 0));
    height: 100%;

    background-color: rgb(255 255 255 / 75%);
    border-radius: inherit;
  }
}
