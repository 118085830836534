.root {
  position: relative;
}

.heading {
  position: relative;
  top: 8px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  margin: 0 var(--g--mx-viewport);
}

.headingTitle {
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin: 0;
  padding: 0;

  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1;
  text-shadow: 0 1px 2px var(--g--glass-dark-100);
  white-space: nowrap;

  outline: 0;

  & > svg {
    width: 12px;
    height: 12px;
    margin-right: var(--g--mx-sm);
    color: var(--g--color-ray-orange);
  }
}

.cardList.cardList {
  --card-scroller--padding-top: var(--g--my-md);
  --card-scroller--padding-bottom: calc(var(--g--my-md) * 2.5);
  --card-scroller--indicator-bottom: calc(100% + 9px);

  /*
  Expand the scrollable container temporarily when there is an active card in
  the list so it won't be clipped.
  */
  &:has([data-card-popover-visible='true'], [data-batch-scrolling='true']) {
    /*
    TODO: We might want to compute the precise amount here too, but for now it's
    okay as long as this value is large enough to prevent clipping in all
    viewport sizes.
    */
    --card-scroller--expand-bottom: 200px;
    --card-scroller--expand-top: 50px;
  }
}
