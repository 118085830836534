.root {
  position: fixed;
  z-index: 3;
  top: 16px;
  right: 0;

  display: flex;

  width: 100%;
  max-width: 616px;
  padding: 0 calc(16px + 14px) 0 16px;

  &.isVisible {
    animation: slideIn 350ms ease-in-out forwards;
  }

  &.isHidden {
    animation: slideOut 350ms ease-in-out forwards;
  }
}

.content {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: 616px;
  padding: 12px 20px;

  background: #0009;
  backdrop-filter: blur(20px);
  border: 1px solid #fffb;
  border-radius: 10px;
  box-shadow: 0 4px 16px 0 #00000059;

  @media (width >= 600px) {
    & {
      flex-wrap: nowrap;
    }
  }
}

.isVisible {
  animation: slideIn 350ms ease-in-out forwards;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  gap: 16px;
  align-items: flex-start;

  @media (width >= 600px) {
    & {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.description {
  margin: 0;
  font-size: 1.4rem;

  @media (width >= 600px) {
    & {
      flex-grow: 2;
      min-width: auto;
      text-wrap: nowrap;
    }
  }
}

.closeButton {
  &:has(svg) {
    width: 18px;
    height: 18px;
    background: none;
  }

  & .closeIcon {
    cursor: pointer;
    color: #fff;
    opacity: 0.4;
    transition: opacity 0.15 ease-out;

    html[data-nav-device='mouse'] .closeButton:hover &,
    html[data-nav-device='keyboard'] .closeButton:focus & {
      opacity: 1;
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(40px);
    opacity: 0;
  }
}
