.root {
  /*
  A computed min-height which accounts for the slider elements when there are
  NO recently played games:
  - Gap: var(--g--my-md).
  - The Featured text block height: 18px.
  - Gap: var(--g--my-md).
  - The logo max height: 16vh.
  - Gap: var(--g--my-md).
  - The description text block max height: 64px.
  - Gap: var(--g--my-md).
  - The featured game actions block height: 34px.
  - Gap: var(--g--my-md).
  - The Recently played text block height: 18px.
  - Gap: var(--g--my-md).
  - The Recently played cards height divided by two: calc(var(--slider--card-height) / 2).
  */
  --slider--min-height: calc(
    var(--g--my-md) + 18px + var(--g--my-md) + 16vh + var(--g--my-md) + 64px + var(--g--my-md) +
      34px + var(--g--my-md) + 18px + var(--g--my-md) + calc(var(--slider--card-height) / 2)
  );
  --slider--card-border-radius: var(--g--card-border-radius);
  --slider--card-gap: var(--g--card-gap);
  --slider--card-extended-edge: var(--g--card-extended-edge);
  --slider--card-action-gap: var(--g--card-action-gap);
  --slider--card-actions-top-margin: var(--g--card-actions-top-margin);
  --slider--card-action-height: var(--g--card-action-height);
  --slider--card-count: 1;
  --slider--card-gap-count: calc(var(--slider--card-count) - 1);
  --slider--card-gapless-area: calc(
    var(--g--100vw) - calc(var(--g--mx-viewport) * 2) -
      calc(var(--slider--card-gap) * var(--slider--card-gap-count))
  );
  --slider--card-width: calc(var(--slider--card-gapless-area) / var(--slider--card-count));
  --slider--card-height: calc(var(--slider--card-width) / (16 / 9));
  --slider--card-active-scale-factor: 1; /* Computed with JS */
  --slider--card-active-scale-extend-y: calc(
    calc(
        calc(var(--slider--card-height) * var(--slider--card-active-scale-factor)) -
          var(--slider--card-height)
      ) / 2
  );

  position: relative;
  height: var(--g--100vh);
  min-height: var(--slider--min-height);
  background-color: var(--g--color-black);

  /*
  A computed min-height which accounts for the slider elements when there are
  recently played games:
  - Gap: var(--g--my-md).
  - The Featured text block height: 18px.
  - Gap: var(--g--my-md).
  - The logo max height: 16vh.
  - Gap: var(--g--my-md).
  - The description text block max height: 64px.
  - Gap: var(--g--my-md).
  - The featured game actions block height: 34px.
  - Gap: var(--g--my-md).
  - The Recently played text block height: 18px.
  - Gap: var(--g--my-md).
  - The Recently played cards height: var(--slider--card-height).
  - Gap: var(--g--my-viewport).
  */
  :global(html.hasRecentlyPlayedGames) & {
    --slider--min-height: calc(
      var(--g--my-md) + 18px + var(--g--my-md) + 16vh + var(--g--my-md) + 64px + var(--g--my-md) +
        34px + var(--g--my-md) + 18px + var(--g--my-md) + var(--slider--card-height) +
        var(--g--my-viewport)
    );
  }

  /*
  We try to keep the card count here in sync with the tag preview card count
  with the exception that there should always be one less card visible in the
  slider. However, doing that in the first two breakpoints would make the cards
  way too tall at some resolutions so we keep the card count identical in
  those. After the second breakpoint, the card count is always one less than
  the tag preview card count.
  */
  @media (width >= 620px) {
    & {
      --slider--card-count: 2;
    }
  }

  @media (width >= 864px) {
    & {
      --slider--card-count: 3;
    }
  }

  @media (width >= 1108px) {
    & {
      --slider--card-count: 3;
    }
  }

  @media (width >= 1352px) {
    & {
      --slider--card-count: 4;
    }
  }

  @media (width >= 1596px) {
    & {
      --slider--card-count: 5;
    }
  }

  @media (width >= 1840px) {
    & {
      --slider--card-count: 6;
    }
  }

  /* The 7 and 8 card setups are aimed for ultrawide screens and beyond. */
  @media (width >= 2084px) and (aspect-ratio >= 2) {
    & {
      --slider--card-count: 7;
    }
  }

  @media (width >= 2328px) and (aspect-ratio >= 3) {
    & {
      --slider--card-count: 8;
    }
  }
}
