.root {
  will-change: transform;

  position: absolute;
  inset: 0;
  transform: translateY(0) scale(var(--app-card--popover-start-scale));

  border-radius: calc(var(--app-card--border-radius) + calc(var(--app-card--popover-padding) / 2));

  transition: transform var(--g--anim-duration) var(--g--anim-easing);

  &.isVisible {
    transform: translateY(var(--app-card--popover-top-offset)) scale(1);
    transition: transform calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing)
      var(--app-card--popover-show-delay);
  }
}

.container {
  position: absolute;
  top: var(--app-card--popover-top);
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  gap: var(--app-card--popover-content-margin);

  width: var(--app-card--popover-width);
  margin: 0;
  padding: var(--app-card--popover-padding);
  padding-top: calc(
    (var(--app-card--popover-padding) * 0.5) + var(--app-card--popover-card-height) +
      var(--app-card--popover-content-margin)
  );

  border-radius: inherit;
}

.bg {
  will-change: opacity;

  position: absolute;
  inset: 0;

  opacity: 0;
  background: var(--g--glass-dark-900);
  backdrop-filter: blur(40px);
  border-radius: inherit;
  box-shadow:
    var(--g--highlight-md),
    0 0 0 1.5px var(--g--glass-light-100),
    var(--g--shadow-xl);

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  .root.isVisible & {
    opacity: 1;
    transition-delay: var(--app-card--popover-show-delay);
  }
}

.content {
  will-change: opacity;

  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  gap: var(--app-card--popover-content-margin);

  width: 100%;

  opacity: 0;

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  .root.isVisible & {
    opacity: 1;
    transition-delay: var(--app-card--popover-show-delay);
  }

  & .title {
    position: relative;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;

    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: var(--g--color-white);
    text-overflow: ellipsis;
    text-shadow: 0 1px 2px var(--g--glass-dark-100);
  }

  & .description {
    position: relative;

    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;

    margin-top: -6px;

    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.25;
    color: var(--g--color-grey);
    text-overflow: ellipsis;
    text-shadow: 0 1px 2px var(--g--glass-dark-100);
  }

  & .tags {
    position: relative;

    overflow: hidden;

    /* display: flex; */

    /*
    Although this is in the design let's hide it fow now as it does not bring
    any value to the user (at the moment) and it makes the card look unnecessarily
    cluttered.
    */
    display: none;
    flex-flow: row nowrap;
    gap: 4px;
    justify-content: flex-start;

    width: 100%;

    & > span {
      overflow: hidden;
      display: block;
      flex-grow: 0;
      flex-shrink: 0;

      max-width: 100%;

      text-overflow: ellipsis;
    }
  }

  & .actions {
    position: relative;
    display: flex;
    gap: var(--app-card--action-gap);

    & .launchAction {
      flex-grow: 1;
      flex-shrink: 1;
    }

    & .infoAction {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}
