.root {
  scroll-snap-align: start;

  position: relative;

  display: flex;
  flex-grow: 0;
  flex-shrink: 0;

  aspect-ratio: 16 / 9;
  width: var(--slider--card-width);
}

.interactionArea {
  pointer-events: none;

  position: absolute;
  inset: calc(var(--slider--card-extended-edge) * -1) calc(var(--slider--card-extended-edge) * -1)
    calc((var(--slider--card-extended-edge) * 2 + var(--slider--card-action-height)) * -1);

  opacity: 0;
  background-color: transparent;
  border-radius: calc(var(--slider--card-border-radius) + var(--slider--card-extended-edge));

  html[data-nav-device='mouse'] .root:hover &,
  html[data-nav-device='keyboard'] .root:focus-within & {
    pointer-events: auto;
  }
}

.imageContainer {
  position: relative;
  transform: scale(1);

  width: 100%;
  height: 100%;

  border-radius: var(--slider--card-border-radius);
  box-shadow: var(--g--shadow-md);

  transition: transform calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing);

  html[data-nav-device='mouse'] .root:hover &,
  html[data-nav-device='keyboard'] .root:focus-within & {
    transform: scale(var(--slider--card-active-scale-factor));
  }

  & > img {
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center center;
    border-radius: var(--slider--card-border-radius);
  }

  & > .imageLink {
    position: absolute;
    inset: 0;

    color: transparent;

    background: transparent;
    border-radius: var(--slider--card-border-radius);
    outline: none;
    box-shadow: var(--g--inset-highlight-md);
  }
}

.title {
  pointer-events: none;

  position: absolute;
  top: calc(100% + var(--g--my-sm));
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 14px;

  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  color: var(--g--color-grey);
  text-overflow: ellipsis;
  white-space: nowrap;

  transition: opacity var(--g--anim-duration) var(--g--anim-easing)
    calc(var(--g--anim-duration) / 2);

  html[data-nav-device='mouse'] .root:hover &,
  html[data-nav-device='keyboard'] .root:focus-within & {
    opacity: 0;
    transition-delay: 0;
    transition-duration: calc(var(--g--anim-duration) / 2);
  }
}

.titleActive {
  pointer-events: none;

  position: absolute;
  bottom: calc(6px - var(--slider--card-active-scale-extend-y));
  left: -2px;

  overflow: hidden;
  display: inline-block;

  max-width: 100%;
  height: 26px;
  padding: 6px;

  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  color: var(--g--color-white);
  text-overflow: ellipsis;
  white-space: nowrap;

  opacity: 0;
  background: var(--g--glass-dark-100);
  backdrop-filter: blur(20px);
  border-radius: var(--g--border-radius-sm);

  transition: opacity var(--g--anim-duration) var(--g--anim-easing);

  html[data-nav-device='mouse'] .root:hover &,
  html[data-nav-device='keyboard'] .root:focus-within & {
    opacity: 1;
    transition-delay: calc(var(--g--anim-duration) / 2);
  }
}

.actions {
  pointer-events: none;

  position: absolute;
  top: calc(
    100% + var(--slider--card-actions-top-margin) + var(--slider--card-active-scale-extend-y)
  );
  left: -8px;
  transform: translateY(
      calc(
        calc(var(--slider--card-actions-top-margin) + var(--slider--card-active-scale-extend-y)) *
          -1
      )
    )
    scale(0.9);

  display: flex;
  gap: var(--slider--card-action-gap);

  width: calc(100% + 16px);

  opacity: 0;

  transition:
    opacity var(--g--anim-duration) var(--g--anim-easing),
    transform var(--g--anim-duration) var(--g--anim-easing);

  html[data-nav-device='mouse'] .root:hover &,
  html[data-nav-device='keyboard'] .root:focus-within & {
    pointer-events: auto;
    transform: translateY(0) scale(1);
    opacity: 1;
  }

  & .launchAction {
    flex-grow: 1;
    flex-shrink: 1;
  }

  & .infoAction {
    flex-grow: 0;
    flex-shrink: 0;
  }
}
