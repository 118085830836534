.root {
  pointer-events: none;

  position: absolute;
  inset: 0;

  overflow: hidden;

  opacity: 0;

  transition: opacity 0.6s ease-in-out;

  &[data-active='true'] {
    pointer-events: auto;
    opacity: 1;
  }
}

.bgImage {
  pointer-events: none;

  position: absolute;
  inset: 0;

  object-fit: cover;
  object-position: center center;
}

.video {
  position: absolute;
  top: 0;
  left: 0;

  display: block;

  width: 100%;
  height: 100%;

  opacity: 0;
  object-fit: cover;
  object-position: center center;

  transition: opacity 0.6s ease-in-out;

  &[data-active='true'] {
    opacity: 1;
  }
}

.sideFade {
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  opacity: 0;
  background-image: linear-gradient(
    -90deg,
    rgb(21 0 17 / 0%) 49.02%,
    rgb(21 0 17 / 0.8%) 52.42%,
    rgb(21 0 17 / 3.5%) 55.82%,
    rgb(21 0 17 / 8.2%) 59.22%,
    rgb(21 0 17 / 15%) 62.62%,
    rgb(21 0 17 / 23%) 66.02%,
    rgb(21 0 17 / 33.3%) 69.41%,
    rgb(21 0 17 / 44.3%) 72.81%,
    rgb(21 0 17 / 55.7%) 76.21%,
    rgb(21 0 17 / 66.7%) 79.61%,
    rgb(21 0 17 / 77%) 83.01%,
    rgb(21 0 17 / 85%) 86.41%,
    rgb(21 0 17 / 91.8%) 89.8%,
    rgb(21 0 17 / 96.5%) 93.2%,
    rgb(21 0 17 / 99.2%) 96.6%,
    rgb(21 0 17) 100%
  );

  transition: opacity 0.4s ease;

  .root[data-active='true'] & {
    opacity: 0.4;
    transition: opacity 0.4s ease 0.4s;
  }
}

.bottomFade {
  pointer-events: none;

  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 40%;

  background-image: linear-gradient(
    180deg,
    rgb(21 0 17 / 0%) 49.02%,
    rgb(21 0 17 / 0.8%) 52.42%,
    rgb(21 0 17 / 3.5%) 55.82%,
    rgb(21 0 17 / 8.2%) 59.22%,
    rgb(21 0 17 / 15%) 62.62%,
    rgb(21 0 17 / 23%) 66.02%,
    rgb(21 0 17 / 33.3%) 69.41%,
    rgb(21 0 17 / 44.3%) 72.81%,
    rgb(21 0 17 / 55.7%) 76.21%,
    rgb(21 0 17 / 66.7%) 79.61%,
    rgb(21 0 17 / 77%) 83.01%,
    rgb(21 0 17 / 85%) 86.41%,
    rgb(21 0 17 / 91.8%) 89.8%,
    rgb(21 0 17 / 96.5%) 93.2%,
    rgb(21 0 17 / 99.2%) 96.6%,
    rgb(21 0 17) 100%
  );
}

.container {
  position: absolute;
  top: 0;
  bottom: calc(calc(var(--g--card-height) / 2) + var(--g--my-md) + 18px);
  left: var(--g--mx-viewport);

  display: flex;
  flex-flow: column nowrap;
  gap: var(--g--my-md);
  align-items: flex-start;
  justify-content: center;

  width: 420px;
  min-width: 320px;
  max-width: 50%;

  :global(html.hasRecentlyPlayedGames) & {
    bottom: calc(var(--slider--card-height) + var(--g--my-md) + var(--g--my-viewport) + 18px);
  }
}

.topContent {
  position: relative;
  transform: translateY(calc(var(--g--my-md) * -1)) scale(0.9);

  display: flex;
  flex-flow: column nowrap;
  gap: var(--g--my-md);

  width: 100%;

  opacity: 0;
  filter: blur(8px);

  transition:
    transform var(--g--anim-duration) var(--g--anim-easing),
    opacity var(--g--anim-duration) linear,
    filter var(--g--anim-duration) linear;

  .root[data-active='true'] & {
    transform: translateY(0) scale(1);
    opacity: 1;
    filter: blur(0);
    transition:
      transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s,
      opacity 0.4s linear 0.4s,
      filter 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;
  }

  & .heading {
    position: relative;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  & .headingTitle {
    position: relative;

    overflow: hidden;
    display: inline-block;

    margin: 0;
    padding: 0;

    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1;
    text-overflow: ellipsis;
    text-shadow: 0 1px 2px var(--g--glass-dark-100);
    white-space: nowrap;

    outline: 0;

    & > svg {
      width: 12px;
      height: 12px;
      margin-right: var(--g--mx-sm);
      color: var(--g--color-ray-orange);
    }
  }

  & .logo {
    display: block;
    max-width: 100%;
    max-height: 16vh;
    margin-right: auto;
  }
}

.bottomContent {
  position: relative;
  transform: translateY(calc(var(--g--my-md) * -1)) scale(0.9);

  display: flex;
  flex-flow: column nowrap;
  gap: var(--g--my-md);

  width: 100%;

  opacity: 0;
  filter: blur(8px);

  transition:
    transform var(--g--anim-duration) var(--g--anim-easing) 0.1s,
    opacity var(--g--anim-duration) linear 0.1s,
    filter var(--g--anim-duration) linear 0.1s;

  .root[data-active='true'] & {
    transform: translateY(0) scale(1);
    opacity: 1;
    filter: blur(0);
    transition:
      transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s,
      opacity 0.5s linear 0.3s,
      filter 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
  }

  & .description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;

    font-size: max(1.7rem, 1.5vh);
    line-height: 1.25;
    color: var(--g--color-white);
    text-overflow: ellipsis;
    text-shadow: 2px 2px 4px var(--g--glass-dark-900);
  }

  & .actions {
    display: flex;
    flex-flow: row nowrap;
    gap: var(--slider--card-action-gap);

    width: 100%;
    max-width: 240px;

    & .launchAction {
      flex-grow: 1;
      flex-shrink: 1;
    }

    & .infoAction {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}
