.root {
  --app-modal--margin: 32px;
  --app-modal--padding: 8px;
  --app-modal--content-margin: 32px;
  --app-modal--content-padding: 24px;
  --app-modal--scrollbar-width: 0px;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: var(--app-modal--margin);

  @media (width <= 767px) {
    & {
      --app-modal--margin: 24px;
      --app-modal--content-margin: 24px;
      --app-modal--content-padding: 16px;
    }
  }
}

.loader {
  position: relative;
  width: calc(100% - var(--app-modal--padding) - var(--app-modal--padding));
  max-width: calc(828px - var(--app-modal--padding) - var(--app-modal--padding));
  height: 4px;
}

.closeButton {
  position: relative;
}

.panel {
  pointer-events: auto;

  position: relative;
  transform: translateY(50px);

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 828px;
  max-height: 100%;

  opacity: 0;
  background: radial-gradient(
      42.9% 50.02% at 50% 50.02%,
      rgb(158 38 81 / 40%) 0%,
      rgb(158 38 81 / 0%) 100%
    ),
    var(--g--color-black);
  border-radius: var(--g--border-radius-md);
  box-shadow:
    var(--g--highlight-md),
    0 0 0 1.5px var(--g--glass-light-100),
    var(--g--shadow-xl);

  transition:
    transform var(--g--anim-duration) ease-in-out,
    opacity var(--g--anim-duration) ease-in-out;

  &.isPanelOpen {
    transform: translateY(0);
    opacity: 1;
    transition:
      transform calc(var(--g--anim-duration) * 2) cubic-bezier(0.125, 0.935, 0.115, 0.955),
      opacity var(--g--anim-duration) linear;
  }
}

.errorPanel {
  flex-flow: row nowrap;
  gap: var(--app-modal--content-margin);
  align-items: flex-start;
  justify-content: space-between;

  max-width: 600px;
  padding: var(--app-modal--content-padding);

  & p {
    position: relative;

    margin: 0;

    font-size: 1.5rem;
    line-height: 1.5;
    word-break: break-word;
  }

  & .closeButton {
    flex-shrink: 0;
  }
}

.contentPanel {
  & .closeButton {
    position: absolute;
    z-index: 2;
    top: 20px;
    right: calc(20px + var(--app-modal--scrollbar-width));
  }

  & .content {
    scroll-behavior: smooth;

    position: relative;

    overflow: hidden;
    overflow-y: auto;
    overscroll-behavior: none;
    flex-grow: 1;
    flex-shrink: 1;

    width: 100%;
    padding: var(--app-modal--padding);

    &::-webkit-scrollbar {
      width: 11px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--g--glass-light-100);
      background-clip: content-box;
      border: 0 solid transparent;
      border-top-width: var(--g--border-radius-md);
      border-right-width: 8px;
      border-bottom-width: var(--g--border-radius-md);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--g--glass-light-900);
    }
  }

  & .videoPlayerContainer {
    position: relative;
    width: 100%;

    & .videoPlayer {
      aspect-ratio: 16 / 9;
      background-color: var(--g--glass-light-100);
      box-shadow: var(--g--shadow-md);
    }
  }

  & .info {
    display: flex;
    flex-flow: row-reverse nowrap;
    gap: var(--app-modal--content-margin);

    margin: var(--app-modal--content-padding);
    margin-top: var(--app-modal--content-margin);

    & .infoLeft {
      position: relative;

      flex-grow: 1;
      flex-shrink: 1;

      width: 100%;
      min-width: 0;

      outline: 0;

      & .title {
        margin: 0;
        font-size: 4rem;
        font-weight: 300;
        line-height: 1.15;
      }

      & .description {
        margin-top: var(--app-modal--content-margin);
      }

      & .tags {
        display: flex;
        flex-flow: row wrap;
        gap: 4px;
        justify-content: flex-start;

        width: 100%;
        margin-top: var(--app-modal--content-margin);

        & > span {
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
        }
      }
    }

    @media (width <= 767px) {
      & {
        flex-flow: column nowrap;
      }
    }
  }

  & .infoRight {
    flex-grow: 0;
    flex-shrink: 0;
    width: clamp(200px, 30%, 300px);

    & .infoRightSticky {
      position: sticky;
      top: var(--app-modal--content-padding);
    }

    & .launchAction {
      width: 100%;
    }

    @media (width <= 767px) {
      & {
        width: 100%;
        min-width: 0;
      }
    }
  }
}
