.root {
  cursor: pointer;
  user-select: none;

  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 34px;
  margin: 0;
  padding: 0 24px;

  font-family: var(--g--font-primary);
  font-size: 1.5rem;
  font-weight: 500;
  font-style: normal;
  line-height: 1;
  color: var(--g--color-white);
  text-decoration: none;
  text-rendering: geometricprecision;
  letter-spacing: 1px;

  background: var(--g--color-jet-black);
  border: 0;
  border-radius: var(--g--button-border-radius);
  outline: 0;

  &:has(svg) {
    width: 46px;
    padding: 0;
  }

  &:is(:disabled, [aria-disabled='true']) {
    cursor: default;
  }

  html[data-nav-device='keyboard'] &:focus {
    outline: 1px solid var(--g--color-white);
    outline-offset: 2px;
  }

  & svg {
    width: 22px;
    height: 22px;
  }
}
