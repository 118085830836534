.root {
  position: relative;

  overflow: hidden;
  display: flex;

  width: 100%;

  border-radius: var(--g--card-border-radius);
}

.poster {
  position: absolute;
  inset: 0;

  width: 100%;
  height: 100%;

  opacity: 1;
  object-fit: cover;
  object-position: center center;

  transition: opacity 0.3s ease-out;

  .root.isPlaying & {
    opacity: 0;
  }
}

.videoContainer {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  & > video {
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;

    transition: opacity 0.3s ease-out;
  }

  .root.isPlaying & > video {
    opacity: 1;
  }
}

.toggleVideo {
  position: absolute;
  bottom: var(--g--my-sm);
  left: var(--g--mx-sm);
  transform: translateX(0) scale(1);

  opacity: 1;

  transition:
    opacity 0.3s,
    transform 0.3s ease-in;

  .root.isPlaying & {
    transform: translateX(-32px) scale(0.75);
    opacity: 0;

    html[data-nav-device='mouse'] .root:hover &,
    html[data-nav-device='keyboard'] .root:focus-within & {
      transform: translateX(0) scale(1);
      opacity: 1;
      transition:
        opacity 0.3s var(--g--anim-bounce-easing),
        transform 0.3s var(--g--anim-bounce-easing);
    }
  }
}

.muteVideo {
  pointer-events: none;

  position: absolute;
  right: var(--g--mx-sm);
  bottom: var(--g--my-sm);
  transform: translateX(32px) scale(0.75);

  opacity: 0;

  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-in;

  .root.isPlaying & {
    html[data-nav-device='mouse'] .root:hover &,
    html[data-nav-device='keyboard'] .root:focus-within & {
      transform: translateX(0) scale(1);
      opacity: 1;
      transition:
        opacity 0.3s var(--g--anim-bounce-easing),
        transform 0.3s var(--g--anim-bounce-easing);
    }

    & > button {
      pointer-events: auto;
    }
  }
}

.highlight {
  pointer-events: none;

  position: absolute;
  inset: 0;

  border-radius: inherit;
  box-shadow: var(--g--inset-highlight-md);
}
