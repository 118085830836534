.root {
  --loading-spinner--bg-color: var(--g--glass-light-100);
  --loading-spinner--color: var(--g--color-ray-orange);
  --loading-spinner--show-duration: 0.5s;
  --loading-spinner--hide-duration: 0.2s;
  --loading-spinner--load-duration: 1s;
  --loading-spinner--show-easing: cubic-bezier(0.125, 0.935, 0.115, 0.955);
  --loading-spinner--hide-easing: ease-in-out;
  --loading-spinner--load-easing: ease-in-out;

  position: absolute;
  inset: 0;
  transform: scaleX(0);

  overflow: hidden;

  transition: transform var(--loading-spinner--hide-duration) var(--loading-spinner--hide-easing);

  &::before {
    content: '';

    position: absolute;
    top: calc(50% - 1px);
    left: 0;

    width: 100%;
    height: 2px;

    border-top: 2px solid var(--loading-spinner--bg-color);
  }

  &::after {
    will-change: transform;
    content: '';

    position: absolute;
    top: calc(50% - 2px);
    left: 0;

    width: 100%;
    height: 4px;

    opacity: 0;
    border-top: 4px solid var(--loading-spinner--color);

    transition: opacity 0s linear var(--loading-spinner--hide-duration);
  }

  &.isActive {
    transform: scaleX(1);
    transition-timing-function: var(--loading-spinner--show-easing);
    transition-duration: var(--loading-spinner--show-duration);
  }

  &.isActive::after {
    opacity: 1;
    transition-delay: var(--loading-spinner--show-duration);
    animation: var(--loading-spinner--load-duration) var(--loading-spinner--load-easing)
      var(--loading-spinner--show-duration) loadingSpinnerAnimation infinite;
  }
}

@keyframes loadingSpinnerAnimation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
