.root {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  & .heading {
    position: relative;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    margin: 0 var(--g--mx-viewport) 0 var(--g--mx-viewport);

    & .headingTitle {
      position: relative;

      overflow: hidden;
      display: inline-block;

      margin: 0;
      padding: 0;

      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1;
      text-overflow: ellipsis;
      text-shadow: 0 1px 2px var(--g--glass-dark-100);
      white-space: nowrap;

      outline: 0;

      & > svg {
        width: 12px;
        height: 12px;
        margin-right: var(--g--mx-sm);
        color: var(--g--color-ray-orange);
      }
    }
  }

  & .items {
    --card-scroller--padding-bottom: var(--g--my-viewport);
    --card-scroller--indicator-bottom: calc(var(--slider--card-gap) - 2px);
  }
}
