.root {
  user-select: text;
  font-size: 1.5rem;
  line-height: 1.5;
  word-break: break-word;

  & p {
    margin: 0 0 var(--g--my-md) 0;
  }

  & hr {
    margin: 0 0 var(--g--my-md) 0;
  }

  & blockquote {
    margin: 0 0 var(--g--my-md) 0;
    padding: 0 0 0 var(--g--mx-sm);
    border-left: 2px solid var(--g--color-white);
  }

  & ol,
  & ul {
    margin: 0 0 var(--g--my-md) 0;
    padding: 0 0 0 24px;
    list-style-position: outside;
  }

  & li ol,
  & li ul {
    margin: 0;
  }

  & .h1,
  & .h2,
  & .h3,
  & .h4,
  & .h5,
  & .h6 {
    margin: 0 0 var(--g--my-md) 0;
    font-weight: 500;
  }

  & .h1 {
    font-size: 2.7rem;
    line-height: 1.2;
  }

  & .h2 {
    font-size: 2.5rem;
    line-height: 1.25;
  }

  & .h3 {
    font-size: 2.3rem;
    line-height: 1.3;
  }

  & .h4 {
    font-size: 2.1rem;
    line-height: 1.35;
  }

  & .h5 {
    font-size: 1.9rem;
    line-height: 1.4;
  }

  & .h6 {
    font-size: 1.6rem;
    line-height: 1.45;
  }

  & a {
    color: var(--g--color-ray-orange);
    text-decoration: none;

    html[data-nav-device='mouse'] &:hover,
    html[data-nav-device='keyboard'] &:focus {
      text-decoration: underline;
    }

    html[data-nav-device='keyboard'] &:not(:disabled):focus {
      border-radius: 4px;
      outline: 1px solid var(--g--color-white);
      outline-offset: 2px;
    }
  }
}
