.root {
  box-shadow: var(--g--shadow-sm);

  & * {
    pointer-events: none;
  }

  & .bgOverlay {
    position: absolute;
    inset: 0;

    display: block;

    opacity: 0;
    border-radius: inherit;

    transition: opacity var(--g--button-anim-duration) var(--g--button-anim-easing);

    &.hover {
      html[data-nav-device='mouse'] .root:hover & {
        opacity: 1;
      }
    }

    &.pressed {
      html[data-nav-device='mouse'] .root:active & {
        opacity: 1;
      }
    }
  }

  & .content {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  & .highlight {
    position: absolute;
    inset: 0;

    display: block;

    border-radius: inherit;
    box-shadow: var(--g--inset-highlight-sm);
  }
}

.themePrimary {
  background: var(--g--color-ray-orange);

  /* stylelint-disable-next-line no-descending-specificity */
  & .bgOverlay.hover {
    background: linear-gradient(0deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 0%) 100%),
      var(--g--color-ray-orange);
    background-blend-mode: soft-light, normal;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  & .bgOverlay.pressed {
    background: linear-gradient(0deg, rgb(0 0 0 / 100%) 0%, rgb(0 0 0 / 0%) 100%),
      var(--g--color-ray-orange);
    background-blend-mode: soft-light, normal;
  }
}

.themeSecondaryLight {
  background: var(--g--glass-light-100);
  backdrop-filter: blur(20px);

  /* stylelint-disable-next-line no-descending-specificity */
  & .bgOverlay.hover {
    background: linear-gradient(0deg, rgb(0 0 0 / 60%) 0%, rgb(0 0 0 / 0%) 100%);
    mix-blend-mode: soft-light;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  & .bgOverlay.pressed {
    background: linear-gradient(0deg, rgb(0 0 0 / 100%) 0%, rgb(0 0 0 / 0%) 100%);
    mix-blend-mode: soft-light;
  }
}

.themeSecondaryDark {
  background: var(--g--glass-dark-100);
  backdrop-filter: blur(20px);

  /* stylelint-disable-next-line no-descending-specificity */
  & .bgOverlay.hover {
    background: rgb(255 255 255 / 3%);
  }

  /* stylelint-disable-next-line no-descending-specificity */
  & .bgOverlay.pressed {
    background: rgb(255 255 255 / 6%);
  }

  & .highlight {
    box-shadow:
      var(--g--inset-highlight-sm),
      inset 0 0 0 1px var(--g--glass-light-100);
  }
}
