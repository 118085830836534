.root {
  position: relative;

  padding: 0 var(--g--mx-viewport);

  font-size: 1.6rem;
  line-height: 1.5;
  color: var(--g--color-grey);

  background: linear-gradient(86deg, #140f20 0.84%, #010e19 99.69%);
}

.content {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  min-height: calc(120px + var(--g--border-radius-md));
  padding-top: calc(var(--g--my-md) + var(--g--border-radius-md));
  padding-bottom: var(--g--my-md);

  @media (width <= 799px) {
    & {
      flex-flow: column nowrap;
      gap: 4px;
      align-items: center;
      justify-content: center;
    }
  }
}

.logo {
  width: auto;
  height: 20px;
  margin-right: auto;
  color: var(--g--color-ray-orange);

  @media (width <= 799px) {
    & {
      display: none;
    }
  }
}

.links {
  display: flex;
  flex-flow: row wrap;
  column-gap: var(--g--mx-md);
  align-items: center;
  justify-content: flex-start;

  margin: 0;
  padding: 0;

  list-style: none;

  & li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  & a {
    display: block;

    padding: 0 4px;

    color: inherit;
    text-decoration: none;

    transition: color var(--g--button-anim-duration) var(--g--button-anim-easing);

    html[data-nav-device='mouse'] &:hover {
      color: var(--g--color-white);
      text-decoration: underline;
    }

    html[data-nav-device='keyboard'] &:not(:disabled):focus {
      border-radius: 4px;
      outline: 1px solid var(--g--color-white);
      outline-offset: 2px;
    }
  }

  @media (width <= 799px) {
    & {
      justify-content: center;
    }
  }
}

.copyright {
  margin: 0 0 0 var(--g--mx-md);
  color: var(--g--color-medium-grey);

  @media (width <= 799px) {
    & {
      width: 100%;
      margin: var(--g--my-sm) 0 0 0;
      text-align: center;
    }
  }
}

.logoStampContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: calc((100vw / 6));

  & .logoStamp {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: auto;

    color: var(--g--color-white);

    opacity: 0.5;
    mix-blend-mode: overlay;
  }
}
