.root {
  display: flex;
  align-items: center;

  height: 25px;
  padding: 0 8px;

  font-size: 1.1rem;
  font-weight: 500;
  line-height: 22px;
  color: var(--g--glass-light-500);
  text-transform: uppercase;
  letter-spacing: 0.44px;
  white-space: nowrap;

  border: 1.5px solid;
  border-radius: var(--g--border-radius-sm);
}
