.root {
  --app-card--border-radius: var(--g--card-border-radius);
  --app-card--action-gap: var(--g--card-action-gap);
  --app-card--action-height: var(--g--card-action-height);
  --app-card--width: var(--g--card-width);
  --app-card--height: var(--g--card-height);
  --app-card--popover-padding: 16px;
  --app-card--popover-content-margin: 16px;
  --app-card--popover-top-offset: -20px;
  --app-card--popover-show-delay: 0.1s;
  --app-card--popover-width: calc(var(--app-card--width) + 48px);
  --app-card--popover-card-width: calc(
    var(--app-card--popover-width) - var(--app-card--popover-padding)
  );
  --app-card--popover-card-height: calc(var(--app-card--popover-card-width) / (16 / 9));
  --app-card--popover-top: calc(
    calc(var(--app-card--popover-padding) * -0.5) +
      calc(calc(var(--app-card--popover-card-height) - var(--app-card--height)) * -0.5)
  );
  --app-card--popover-start-scale: calc(
    var(--g--card-width-unitless) / (var(--g--card-width-unitless) + 32)
  );
  --app-card--image-expanded-scale: calc(
    (var(--g--card-width-unitless) + 32) / var(--g--card-width-unitless)
  );

  /*
  Computed max-height of the popover content:
  + top padding: calc(var(--app-card--popover-padding) / 2)
  + popover card height: var(--app-card--popover-card-height)
  + margin: var(--app-card--popover-content-margin)
  + title height: 18px
  + margin: 12px
  + description height: 49px
  + margin: var(--app-card--popover-content-margin)
  + actions height: var(--app-card--action-height)
  + bottom padding: var(--app-card--popover-padding)
  */
  --app-card-popover-max-height: calc(
    calc(var(--app-card--popover-padding) / 2) + var(--app-card--popover-card-height) +
      var(--app-card--popover-content-margin) + 18px + 12px + 49px +
      var(--app-card--popover-content-margin) + var(--app-card--action-height) +
      var(--app-card--popover-padding)
  );

  scroll-snap-align: start;

  position: relative;
  z-index: 1;

  display: flex;
  flex-grow: 0;
  flex-shrink: 0;

  aspect-ratio: 16 / 9;
  width: var(--app-card--width);

  &.isPopoverVisible {
    z-index: 9;
  }

  &.isActive {
    z-index: 10;
  }
}

.scrollToTarget {
  pointer-events: none;

  position: absolute;
  top: calc(var(--app-card--popover-top) + var(--app-card--popover-top-offset));
  right: 0;
  left: 0;

  height: var(--app-card-popover-max-height);

  opacity: 0;
}

.focusTarget {
  pointer-events: none;

  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  height: 1px;

  opacity: 0;
}

.imageContainer {
  position: relative;
  transform: translateY(0) scale(1);

  width: 100%;
  height: 100%;

  border-radius: var(--app-card--border-radius);
  box-shadow: var(--g--shadow-md);

  transition: transform var(--g--anim-duration) var(--g--anim-easing);

  .root.isActive &,
  .root.isPopoverVisible & {
    will-change: transform;
  }

  .root.isActive.isPopoverVisible & {
    transform: translateY(var(--app-card--popover-top-offset))
      scale(var(--app-card--image-expanded-scale));
    transition: transform calc(var(--g--anim-duration) * 1.5) var(--g--anim-bounce-easing)
      var(--app-card--popover-show-delay);
  }

  & > .image {
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center center;
    border-radius: inherit;
  }

  & > .video {
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    opacity: 0;
    object-fit: cover;
    object-position: center center;
    background-color: var(--g--color-jet-black);
    border-radius: inherit;

    transition: opacity var(--g--anim-duration) var(--g--anim-easing);

    html[data-nav-device='mouse'] &.isPlaying,
    html[data-nav-device='keyboard'] &.isPlaying {
      opacity: 1;
      transition-duration: calc(var(--g--anim-duration) * 1.5);
    }
  }

  & > .imageLink {
    position: absolute;
    inset: 0;

    color: transparent;

    background: transparent;
    border-radius: inherit;
    outline: none;
    box-shadow: var(--g--inset-highlight-md);
  }
}

.title {
  pointer-events: none;

  position: absolute;
  top: calc(100% + var(--g--my-sm));
  left: 0;

  overflow: hidden;

  width: 100%;
  height: 14px;

  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  color: var(--g--color-grey);
  text-overflow: ellipsis;
  white-space: nowrap;
}
